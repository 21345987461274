var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'account',
        _vm.validatorRules.checkAccount ]),expression:"[\n        'account',\n        validatorRules.checkAccount,\n      ]"}],ref:"aInput",attrs:{"size":"large","type":"text","placeholder":"请输入账号","autocomplete":"“off”"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          _vm.validatorRules.checkPassword ]),expression:"[\n          'password',\n          validatorRules.checkPassword,\n        ]"}],attrs:{"size":"large","placeholder":"请输入密码","autocomplete":"“off”"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phone',
          _vm.validatorRules.checkPhone ]),expression:"[\n          'phone',\n          validatorRules.checkPhone,\n        ]"}],attrs:{"size":"large","placeholder":"请输入手机号码","onInput":"if(value.length>11)value=value.slice(0,11)"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"phone"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-row',{staticStyle:{"width":"100%"}},[_c('a-col',{attrs:{"span":12}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              { rules: [{ required: true, message: '请输入验证码' }] } ]),expression:"[\n              'code',\n              { rules: [{ required: true, message: '请输入验证码' }] },\n            ]"}],attrs:{"size":"large","placeholder":"请输入验证码","onInput":"if(value.length>6)value=value.slice(0,6)"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"shake"},slot:"prefix"})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-button',{staticClass:"code_button",attrs:{"type":"primary","disabled":_vm.codeDisabled},on:{"click":_vm.getCode}},[_vm._v(_vm._s(_vm.codeText))])],1)],1)],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.loginBtn,"disabled":_vm.loginBtn},on:{"click":_vm.login}},[_vm._v("登录")])],1),(_vm.isLoginError)?_c('a-alert',{attrs:{"message":"账户或密码错误","type":"error","show-icon":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }