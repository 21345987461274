<template>
  <div class="main">
    <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
    >
      <a-form-item>
        <a-input
        size="large"
        type="text"
        placeholder="请输入账号"
        autocomplete=“off” 
        ref="aInput"
        v-decorator="[
          'account',
          validatorRules.checkAccount,
        ]"
        >
          <a-icon
            slot="prefix"
            type="user"
            :style="{ color: 'rgba(0,0,0,.25)' }"
          />
        </a-input>
      </a-form-item>

      <a-form-item>
        <a-input-password
          size="large"
          placeholder="请输入密码"
          autocomplete=“off” 
         v-decorator="[
            'password',
            validatorRules.checkPassword,
          ]"
        >
          <a-icon
            slot="prefix"
            type="lock"
            :style="{ color: 'rgba(0,0,0,.25)' }"
          />
        </a-input-password>
      </a-form-item>




      <a-form-item>
        <a-input
          size="large"
          placeholder="请输入手机号码"
          onInput="if(value.length>11)value=value.slice(0,11)"
          v-decorator="[
            'phone',
            validatorRules.checkPhone,
          ]"
        >
          <a-icon
            slot="prefix"
            type="phone"
            :style="{ color: 'rgba(0,0,0,.25)' }"
          />
        </a-input>
      </a-form-item>



      <a-form-item>
        <a-row style="width: 100%;">
          <a-col :span="12"> 
            <a-input
              size="large"
              placeholder="请输入验证码"
              v-decorator="[
                'code',
                { rules: [{ required: true, message: '请输入验证码' }] },
              ]"
              onInput="if(value.length>6)value=value.slice(0,6)"
            > 
              <a-icon
                slot="prefix"
                type="shake"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input>
          </a-col>
          <a-col :span="12">
            <a-button class="code_button" type="primary" :disabled="codeDisabled" @click="getCode">{{codeText}}</a-button>
          </a-col>
        </a-row>
      </a-form-item>

      <a-form-item style="margin-top:24px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          @click="login"
          :loading="loginBtn"
          :disabled="loginBtn"
          >登录</a-button
        >
      </a-form-item>
      <a-alert
        message="账户或密码错误"
        type="error"
        show-icon
        v-if="isLoginError"
      />
    </a-form>
  </div>
</template>

<script>
import API from "@/request/index";
export default {
  data() {
    return {
      timer:null,
      account:'',
      password:'',
      codeDisabled:false, //禁用按钮
      codeText:'获取验证码',
      phoneNumber:'',  //手机号码
      phoneRight:false, // 手机号码是否正确
      loginBtn: false,
      isLoginError: false,
      form: this.$form.createForm(this), // 注册表单
      validatorRules: {
        checkPhone: {
          rules: [
            { required: true, message: '' }, //此处开启校验必填
            { validator: this.validatePhone } //校验规则函数，
          ],
          trigger: 'blur' // 触发方式
        },
        checkAccount: {
          rules: [
            { required: true, message: '' }, //此处开启校验必填
            { validator: this.validateAccount } //校验规则函数，
          ],
          trigger: 'blur' // 触发方式
        },
        checkPassword: {
          rules: [
            { required: true, message: '' }, //此处开启校验必填
            { validator: this.validatePassword } //校验规则函数，
          ],
          trigger: 'blur' // 触发方式
        },
      }
    };
  },
  mounted(){
    this.$refs.aInput.focus()
  },
  methods: {
    // 登录方法
    async login() {
      // 表单校验
      this.form.validateFields(async (err) => {
    
        
        // 如果没有校验报错则去请求登录
        if (!err) {
          this.loginBtn = true;
          // 获取表单信息
          let params = this.form.getFieldsValue();
          console.log(params);
          
          // 调用登录接口
          let res = await API.Login({
            account: params.account,
            password: params.password,
            phone: params.phone,
            smsCode: params.code,
          }).catch((err) => {
            this.loginBtn = false;
          });
          // 对数据做处理 报错token 到 storage
          if (res.data.code === "0000") {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("userId", res.data.data.userId);
            localStorage.setItem("nickName", res.data.data.nickName);
            localStorage.setItem("menuPathList", JSON.stringify(res.data.data.menuList));
            this.loginBtn = false;
            // 调用成功
            await this.$router.push("/welcome");
            this.$message.success("登录成功", 1);
          } else {
            this.isLoginError = true;
            this.loginBtn = false;
          }
        }
      });
    },
    // 校验手机号码
    validatePhone(rule, value, callback){
      let reg = /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/
      if (!reg.test(value)) {
        this.phoneNumber = value
        this.phoneRight = false
        callback(new Error('请输入正确的手机号码'));
      } else {
        this.phoneNumber = value
        this.phoneRight = true
        callback();
      }
    },
    // 校验账号
    validateAccount(rule, value, callback){
      if (value.length <= 2) {
        this.account = value
        callback(new Error('请输入正确的账号'));
      } else {
        this.account = value
        callback();
      }
    },
    // 校验密码
    validatePassword(rule, value, callback){
      if (value.length <= 5) {
        this.password = value
        callback(new Error('请输入正确的密码'));
      } else {
        this.password = value
        callback();
      }
    },
    async getCode(){
      if(this.account && this.password  && this.phoneRight){
        let res = await API.getSmsCode({
          phone: this.phoneNumber,
          account: this.account,
          password: this.password,
          type: 1,
        }).catch((err) => {
          this.loginBtn = false;
        });
        if(res.data.code === '0000'){
        this.countDown()
        }
      }
    },
    // 倒计时
    countDown(){
      this.codeDisabled = true
      let num = 60
      this.timer = setInterval(()=>{
        num--
        if(num <= 0 ){
          num = 0
          clearInterval(this.timer)
            this.codeDisabled = false
          this.codeText = '获取验证码'
        }else{
          this.codeText = `${num}s 后重新获取`
        }
      },1000)
    },
    beforeDestroy(){
      clearInterval(this.timer)
      this.timer = null
    },
    
  },
};
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    margin-top: 24px;
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }
}
ant-col{
  width:100%
}
.code_button{
  display: block;
  float:right;
  height: 38px;
}
</style>
